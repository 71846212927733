import { Injectable } from '@angular/core';
import { EntityService } from 'src/app/admin/services/entity.service';
import { BBG } from 'src/app/core/models/server-dto';
import Booking = BBG.Transfer.Booking;
import BookingHistory = BBG.Transfer.BookingHistory;
import { HttpRequestService } from 'src/app/core/services/http-request/http-request.service';
import { listQuery2HttpParams } from 'nl';

export interface BookingYachtParams {
  startDate: string;
  endDate: string;
  onlyFullyAvailable: boolean;
  filterOutYachtIds: number[];
  filterOutBookingId: number;
}

@Injectable({
  providedIn: 'root'
})
export class BookingService extends
  EntityService<
  number,
  Booking.BookingAdminDto,
  Booking.BookingBasicDto,
  Booking.Query.ListBookingAdminQuery,
  Booking.Command.CreateBookingAdminCommand,
  Booking.Command.UpdateBookingAdminCommand
  > {

  constructor(
    httpRequestService: HttpRequestService
  ) {
    super(httpRequestService, 'api/admin/booking');
  }

  history(listQuery: BookingHistory.Query.ListBookingHistoryQuery) {
    const params = listQuery2HttpParams(listQuery);

    return this.httpRequestService
      .get<BBG.Transfer.PagedListDto<BBG.Transfer.BookingHistory.BookingHistoryDto>>(
        'api/booking-history',
        { params, headers: this.headers }
      );

  }

  createOrUpdateInvoice(id: number) {
    return this.httpRequestService
      .get<string>(`api/admin/booking/${id}/invoice/issue`, { headers: this.headers });
  }
}
