import { Injectable } from '@angular/core';
import { HttpRequestService } from 'src/app/core/services/http-request/http-request.service';
import { BBG } from '../../../core/models/server-dto';
import { listQuery2HttpParams } from 'nl';
import Payment = BBG.Transfer.Payment;

@Injectable({
  providedIn: 'root'
})
export class PaymentApiService {

  static API_PREFIX = 'api/payment';

  constructor(private httpRequestService: HttpRequestService) { }

  get(paymentId: number) {
    return this.httpRequestService.get(`${PaymentApiService.API_PREFIX}/${paymentId}`)
  }

  list(listQuery: Payment.Query.ListPaymentQuery) {

    const params = listQuery2HttpParams(listQuery);
    return this.httpRequestService.get<BBG.Transfer.PagedListDto<Payment.PaymentDto>>(`${PaymentApiService.API_PREFIX}`, { params })
  }

  payUPaymentForBooking(bookingId: number) {
    const command: Payment.Command.CreatePaymentCommand = {
      bookingId
    }
    return this.httpRequestService.post<Payment.PayuPaymentCreateResultDto>(`${PaymentApiService.API_PREFIX}/payu`, command)
  }
}
