import { Injectable } from '@angular/core';
import { EntityService } from '../../services/entity.service';
import { BBG } from 'src/app/core/models/server-dto';
import Payment = BBG.Transfer.Payment;
import { HttpRequestService } from 'src/app/core/services/http-request/http-request.service';
import { Observable } from 'rxjs';
import { map, reduce } from 'rxjs/operators';
import { streamAllPages } from 'nl';

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends
  EntityService<
  number,
  Payment.PaymentAdminDto,
  Payment.PaymentAdminDto,
  Payment.Query.ListPaymentQuery,
  Payment.Command.CreatePaymentAdminCommand,
  Payment.Command.UpdatePaymentAdminCommand
  > {
  constructor(
    httpRequestService: HttpRequestService
  ) {
    super(httpRequestService, 'api/admin/payment');
  }

  getAllPayments(bookingId: number): Observable<Payment.PaymentAdminDto[]> {
    return streamAllPages<Payment.Query.ListPaymentQuery, Payment.PaymentAdminDto>({
      pageIndex: 0,
      pageSize: 100,
      orderBy: null,
      searchBy: [],
      bookingId: bookingId
    },
      (pageQuery) => {
        return this.list(pageQuery)
          .pipe(map(response => response.data));
      }
    ).pipe(
      reduce((acc, curr) => [...acc, ...curr], [])
    );
  }
}
