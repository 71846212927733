import { Injectable } from '@angular/core';
import { HttpRequestService } from '../../../core/services/http-request/http-request.service';
import { BBG } from '../../../core/models/server-dto';
import Booking = BBG.Transfer.Booking;
import { listQuery2HttpParams } from 'nl';
@Injectable({
  providedIn: 'root'
})
export class BookingApiService {
  static API_PREFIX = 'api/booking';
  constructor(private httpRequestService: HttpRequestService) { }

  create(command: Booking.Command.CreateBookingCommand){
    return this.httpRequestService.post<Booking.BookingDto>(
      `${BookingApiService.API_PREFIX}`,
      command
    );
  }

  list(listQuery: Booking.Query.ListBookingQuery){
    const params = listQuery2HttpParams(listQuery);
    return this.httpRequestService.get<BBG.Transfer.PagedListDto<Booking.BookingBasicDto>>(`${BookingApiService.API_PREFIX}`, {params})
  }

  get(bookingId: number) {
    return this.httpRequestService.get<Booking.BookingDto>(`${BookingApiService.API_PREFIX}/${bookingId}`);
  }
}
