import { HttpErrorResponse } from '@angular/common/http';
import { camelCase } from 'change-case';
import { BBG } from 'src/app/core/models/server-dto';

const DefaultPrefix = 'httpError';

export function httpErrorTranslation(error, prefix = DefaultPrefix) {
  if (error instanceof HttpErrorResponse && error.status !== 0) {
    const apiError: BBG.Transfer.ErrorDto = error.error.error;
    return `${prefix}.${camelCase(apiError.codeName)}`;
  } else {
    return `${DefaultPrefix}.unknownError`;
  }
}