import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class BackgroundImageService {

  private _subject = new BehaviorSubject(true);
  private _showBackgroundImage$ = this._subject.asObservable().pipe(distinctUntilChanged());
  private _getBackgroundStyle$ = this._subject.asObservable().pipe(distinctUntilChanged());

  get getBackgroundStyle$(){
    return this._getBackgroundStyle$;
  }

  get showBackgroundImage$(){
    return this._showBackgroundImage$;
  }

  hide() {
    this._subject.next(false);
  }

  show() {
    this._subject.next(true);
  }
}
