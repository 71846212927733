import { Injectable } from '@angular/core';
import { HttpRequestService } from 'src/app/core/services/http-request/http-request.service';
import { BBG } from 'src/app/core/models/server-dto';
import { EntityService } from 'src/app/admin/services/entity.service';
import YachtClass = BBG.Transfer.YachtClass;
import { Observable } from 'rxjs';
import { map, reduce } from 'rxjs/operators';
import { streamAllPages } from 'nl';

@Injectable({
  providedIn: 'root'
})
export class YachtClassService extends
  EntityService<
  number,
  YachtClass.YachtClassDto,
  YachtClass.YachtClassDto,
  YachtClass.Query.ListYachtClassQuery,
  YachtClass.Command.CreateYachtClassCommand,
  YachtClass.Command.UpdateYachtClassCommand
  > {
  constructor(
    httpRequestService: HttpRequestService
  ) {
    super(httpRequestService, 'api/yacht-class');
  }

  getAllYachtClass(orderBy: BBG.Transfer.OrderByQuery): Observable<YachtClass.YachtClassDto[]> {
    return streamAllPages<BBG.Transfer.YachtClass.Query.ListYachtClassQuery, BBG.Transfer.YachtClass.YachtClassDto>({
      pageIndex: 0,
      pageSize: 100,
      orderBy,
      searchBy: []
    },
      (pageQuery) => {
        return this.list(pageQuery)
          .pipe(map(response => response.data));
      }
    ).pipe(
      reduce((acc, curr) => [...acc, ...curr], [])
    );
  }
}
