import { Injectable } from '@angular/core';
import {
  HttpRequestService
 } from 'src/app/core/services/http-request/http-request.service';
import { BBG } from 'src/app/core/models/server-dto';

@Injectable({
  providedIn: 'root'
})
export class ExtProviderApiService {

  static API_PREFIX = 'api/external-provider';

  constructor(private httpRequest: HttpRequestService) { }

  list() {
    return this.httpRequest.get<BBG.Transfer.ExternalProvider.ExternalProviderDto[]>(
      `${ExtProviderApiService.API_PREFIX}/list`);
  }

  userList() {
    return this.httpRequest.get<BBG.Transfer.ExternalProvider.UserExternalProviderDto[]>(
      `${ExtProviderApiService.API_PREFIX}/user-external-providers`);

  }

}
