import { Injectable } from '@angular/core';
import { EntityService } from 'src/app/admin/services/entity.service';
import { BBG } from 'src/app/core/models/server-dto';
import YachtPrice = BBG.Transfer.YachtPrice;
import { HttpRequestService } from 'src/app/core/services/http-request/http-request.service';
import { Observable } from 'rxjs';
import { map, reduce } from 'rxjs/operators';
import { streamAllPages } from 'nl';
import * as moment from 'moment';
import { reCalcDateToUTC } from '../../../../core/utils/recalculate-date-to-utc';
@Injectable({
  providedIn: 'root'
})
export class YachtPriceService
  extends EntityService<
  number,
  YachtPrice.YachtPriceDto,
  YachtPrice.YachtPriceDto,
  YachtPrice.Query.ListYachtPriceQuery,
  YachtPrice.Command.CreateYachtPriceCommand,
  YachtPrice.Command.UpdateYachtPriceCommand
  > {

  constructor(
    httpRequestService: HttpRequestService
  ) {
    super(httpRequestService, 'api/yacht-price')
  }

  getAllYachtPrice(yachtIds: number[], fromNow = true): Observable<YachtPrice.YachtPriceDto[]> {
    return streamAllPages<YachtPrice.Query.ListYachtPriceQuery, YachtPrice.YachtPriceDto>({
      pageIndex: 0,
      pageSize: 100,
      orderBy: null,
      searchBy: [],
      yachtIds: yachtIds,
      startDate: fromNow ? moment().startOf('d').toISOString(): null
    },
      (pageQuery) => {
        return this.list(pageQuery)
          .pipe(map(response => response.data));
      }
    ).pipe(
      reduce((acc, curr) => [...acc, ...curr], [])
    );
  }

}
