import { Injectable } from '@angular/core';
import { HttpRequestService } from '../../../../core/services/http-request/http-request.service';
import { BBG } from '../../../../core/models/server-dto';
import Gps = BBG.Transfer.Gps;
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class GpsApiService {

  constructor(
    protected httpRequestService: HttpRequestService
  ) { }

  historical(query: Gps.Query.ListHistoricalGpsDataQuery): Observable<Gps.YachtHistoricalGpsDataDto[]> {
    let params = new HttpParams({
      fromObject: {
        dateFrom: query.dateFrom,
        dateTo: query.dateTo
      }
    })

    if (query.gpsIds) {
      query.gpsIds.forEach(id => {
        params = params.append('gpsIds', id.toString())
      });
    }

    return this.httpRequestService
      .get<Gps.YachtHistoricalGpsDataDto[]>(
        `api/gps/data/historical`,
        {
          params
        }
      )
      .pipe(
        map(response => response.data)
      );
  }

  current(query: Gps.Query.ListCurrentGpsDataQuery): Observable<Gps.YachtCurrentGpsDataDto[]> {
    let params = new HttpParams({
      fromObject: {}
    })

    if (query.gpsIds) {
      query.gpsIds.forEach(id => {
        params = params.append('gpsIds', id.toString())
      });
    }

    return this.httpRequestService
      .get<Gps.YachtCurrentGpsDataDto[]>(
        `api/gps/data/current`,
        {
          params
        }
      )
      .pipe(
        map(response => response.data)
      );
  }

  getAll(){
    return this.httpRequestService
      .get<Gps.GpsDto[]>(
        `api/gps`,
      )
      .pipe(
        map(response => response.data)
      );
  }

  getBookingHistorical(bookingId: number): Observable<Gps.YachtHistoricalGpsDataDto[]>{
    return this.httpRequestService
      .get<Gps.YachtHistoricalGpsDataDto[]>(
        `api/gps/data/booking/${bookingId}`,
      )
      .pipe(
        map(response => response.data)
      );
  }

  getBookingCurrent(bookingId: number): Observable<Gps.YachtCurrentGpsDataDto[]>{
    return this.httpRequestService
      .get<Gps.YachtCurrentGpsDataDto[]>(
        `api/gps/data/booking/${bookingId}/current`,
      )
      .pipe(
        map(response => response.data)
      );
  }


}
