import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AfterAuthUrlService {

  private _url: string;

  constructor(
    private _router: Router
  ) { }

  set url(url: string) {
    this._url = url;
  }

  navigate() {
      this._router.navigateByUrl(this._url ? this._url : '');
      this._url = null;
  }
}
