import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { httpErrorTranslation } from '../parse-http-error';
import { pendingFlag } from 'nl';

export const apiError =
  (errCallback: (errorI18n: string) => void, errorPrefix?: string) =>
    <T>(source: Observable<T>) =>
      source
        .pipe(
          pendingFlag(pen => {
            if (pen) {
              errCallback(null);
            }
          }),
          catchError(error => {
            errCallback(httpErrorTranslation(error, errorPrefix));
            return throwError(error);
          })
        );
