import { Injectable } from '@angular/core';
import { EntityService } from '../../services/entity.service';
import { BBG } from 'src/app/core/models/server-dto';
import User = BBG.Transfer.User;
import { HttpRequestService } from 'src/app/core/services/http-request/http-request.service';
@Injectable({
  providedIn: 'root'
})
export class UserService extends
  EntityService<
  string,
  User.UserDto,
  User.UserDto,
  User.Query.ListUserQuery,
  User.Command.CreateUserCommand,
  User.Command.UpdateUserCommand
  > {
  constructor(
    httpRequestService: HttpRequestService
  ) {
    super(httpRequestService, 'api/user');
  }

}
