import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AfterAuthUrlService } from '../services/after-auth-url/after-auth-url.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private afterAuthUrlService: AfterAuthUrlService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isAuthorized()
      .pipe(
        map(isAuth => {
          if(!isAuth){
            this.router.navigateByUrl('/login');
            this.afterAuthUrlService.url = state.url;
            return false;
          }
          return true;
        })
      )
  }

}
